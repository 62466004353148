<template>
	<div id="sidenav" tabindex="1" class="sidenav" :style="{width: currentWidth}" @mouseenter="toggleNav" @mouseleave="toggleNav">
		<div class="">
			<img id="logo" class="logo" src="@/assets/images/alpha_black.png">
		</div>
		<div class="links">
			<ul>
				<NavItem :show_text="expanded" idx="0" title="Dashboard" src="home" href="/"></NavItem>
				<NavItem :show_text="expanded" idx="1" title="Oasis" src="gamepad" href="/oasis"></NavItem>
				<NavItem :show_text="expanded" idx="2" title="Map" src="globe" href="/map"></NavItem>
				<NavItem :show_text="expanded" idx="2" title="Estate Manager" src="building" href="/estate"></NavItem>
				<NavItem :show_text="expanded" idx="3" title="NFT Import" src="file-import" href="/nft-import"></NavItem>
				<NavItem :show_text="expanded" idx="4" title="Social" src="user-friends" href="/social"></NavItem>
				<NavItem :show_text="expanded" idx="5" title="Auction House" src="cash-register" href="/marketplace"></NavItem>
				<NavItem :show_text="expanded" idx="5" title="Shop" src="store" href="/shop"></NavItem>
				<NavItem :show_text="expanded" idx="5" title="MetaVerse Browser" src="layer-group" href="/metaverses"></NavItem>
				<NavItem :show_text="expanded" idx="6" title="Settings" src="sliders-h" href="/settings"></NavItem>
			</ul>
		</div>
		
		<div class="auth m-1 mt-5">
			<router-link to="/profile" v-if="connected">
				<div class="user row">
					<img class="user_avatar ml-3"
						:src="user.avatar_url"
						alt="">
					<div class="user_detail col-7 p-0 ml-1 wow fadeIn" data-animation-delay="0.3s" v-if="expanded">
						<h5 class="user_name">{{ user.name }}</h5>
						<h5 class="user_wallet">{{ user.balance }} ABMT</h5>
					</div>
				</div>
			</router-link>
			<div v-if="expanded">
				<button v-if="connected" v-on:click="logout" class="btn btn-block btn-primary mt-2 wow fadeIn" data-animation-delay="0.3s">LOGOUT</button>
				<Phantom @connected="onConnect" v-if="!connected" class="btn btn-primary btn-block btn-sm mt-2 wow fadeIn"></Phantom>
			</div>
		</div>
	</div>
</template>

<script>
	import NavItem from "./NavItem";
	import Phantom from "./wallet/Phantom";
	import User from "@/api/user"
	import Auth from "@/api/auth"
	
	export default {
		name: "Sidenav",
		components: {Phantom, NavItem},
		data() {
			return {
				user: {
					name: "TheCloakdOne",
					balance: "0.00",
					wallet_addr: "0x1b47f49a325EFC79ad3a8d780b77Ce74bbF9a545",
					avatar_url: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w50"
				},
				connected: false,
				expanded: false
			}
		},
		computed: {
			currentWidth: function () {
				return this.expanded ? "200px" : "60px";
			}
		},
		methods: {
			toggleNav: function () {
				this.expanded = !this.expanded
			},
			
			onConnect: function (success) {
				this.connected = success
				this.getProfile()
			},
			
			logout: function() {
				console.log("Logging out")
				window.solana.disconnect();
				this.$store.commit('set_wallet_addr', "");
				this.$router.push("/login")
			},
			
			login: function() {
				this.signMessage("Login to AlphaBatem").then((sig) => {
					let payload = {
						"wallet_addr": this.$store.state.wallet_addr,
						"signature": sig,
					};
					
					Auth.create(payload).then(response => {
						console.log("resp", response)
						this.$router.push("/profile/update")
					});
				})
			},
			
			getProfile: function () {
				User.show(this.$store.state.wallet_addr).then(resp => {
					this.user = resp.data;
					this.user.balance = "0.00" //TODO Remove
				}).catch(err => {
                    console.log(err)
					this.login();
                })
			}
		}
	}
</script>

<style scoped>
	.sidenav {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 2;
		/*width: 200px;*/
		background: #0E0E0F;
		/*background: linear-gradient(90deg, rgba(22, 99, 117, 1) 0%, rgba(14, 64, 76, 1) 100%);*/
		height: 100vh;
		max-height: 100vh;
		border-right: 2px solid #0a9fb9;
		transition: width 0.3s ease-out;
	}
	
	.auth {
		position: absolute;
		bottom: 5px;
		left: 1px;
		width: 185px;
	}
	
	.logo {
		cursor: pointer;
	}
	
	.user_avatar, .logo {
		max-height: 50px;
		border-radius: 8px;
	}
	
	.user_detail {
		position: relative;
		display: block;
		overflow: hidden;
	}
	
	.user_detail h5 {
		color: white;
	}
	
	.btn-block {
		overflow: hidden;
	}
	
	h1 {
		color: white;
		text-align: center;
		font-size: 1.5rem;
		margin-bottom: 20px;
	}
	
	.links ul:first-child {
		border-top: 2px solid #0a9fb9;
	}
</style>