import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "home" */ '../views/dashboard/Dashboard.vue')
    },
    {
        path: '/',
        name: 'Login',
        component: () => import(/* webpackChunkName: "home" */ '../views/auth/Login.vue')
    },
    {
        path: '/sagas',
        name: 'Saga Index',
        component: () => import(/* webpackChunkName: "saga_index" */ '../views/sagas/SagaIndex.vue')
    },
    {
        path: '/sagas/:id',
        name: 'Saga Show',
        component: () => import(/* webpackChunkName: "saga_show" */ '../views/sagas/SagaShow.vue')
    },
    {
        path: '/sagas/:id/items',
        name: 'Saga Item Index',
        component: () => import(/* webpackChunkName: "saga_item_index" */ '../views/sagas/SagaItemIndex.vue')
    },
    {
        path: '/sagas/:saga_id/items/:id',
        name: 'Saga Item Show',
        component: () => import(/* webpackChunkName: "item_show" */ '../views/items/ItemShow.vue')
    },
    {
        path: '/marketplace',
        name: 'Marketplace',
        component: () => import(/* webpackChunkName: "item_index" */ '../views/marketplace/MarketIndex.vue')
    },
    {
        path: '/shop',
        name: 'Shop',
        component: () => import(/* webpackChunkName: "item_index" */ '../views/shop/ShopIndex.vue')
    },
    {
        path: '/shop/land',
        name: 'Land Shop',
        component: () => import(/* webpackChunkName: "item_index" */ '../views/shop/ShopLandIndex.vue')
    },
    {
        path: '/marketplace/collections',
        name: 'Marketplace Collections',
        component: () => import(/* webpackChunkName: "item_index" */ '../views/marketplace/MarketIndex.vue') //TODO
    },
    {
        path: '/marketplace/collections/:id',
        name: 'Marketplace Collection',
        component: () => import(/* webpackChunkName: "item_index" */ '../views/marketplace/MarketIndex.vue') //TODO
    },
    {
        path: '/marketplace/assets/:id',
        name: 'Item',
        component: () => import(/* webpackChunkName: "item_index" */ '../views/marketplace/MarketShowItem.vue')
    },
    {
        path: '/items',
        name: 'Item Index',
        component: () => import(/* webpackChunkName: "item_index" */ '../views/items/ItemIndex')
    },
    {
        path: '/items/:id',
        name: 'Item Show',
        component: () => import(/* webpackChunkName: "item_show" */ '../views/items/ItemShow.vue')
    },
    {
        path: '/players',
        name: 'Player Index',
        component: () => import(/* webpackChunkName: "player_index" */ '../views/players/PlayerIndex.vue')
    },
    {
        path: '/players/:id',
        name: 'Player Show',
        component: () => import(/* webpackChunkName: "player_show" */ '../views/profile/PublicProfile.vue')
    },
    {
        path: '/profile',
        name: 'Player Profile',
        component: () => import(/* webpackChunkName: "player_index" */ '../views/profile/ProfileIndex.vue')
    },
    {
        path: '/profile/update',
        name: 'Player Profile',
        component: () => import(/* webpackChunkName: "player_index" */ '../views/profile/ProfileUpdate.vue')
    },
    {
        path: '/map',
        name: 'Map',
        component: () => import(/* webpackChunkName: "room_index" */ '../views/land/LandIndex2')
    },
    {
        path: '/map/sector',
        name: 'Sector Map',
        component: () => import(/* webpackChunkName: "room_index" */ '../views/map/SectorMap')
    },
    {
        path: '/map/sector/:id',
        name: 'Sector Show',
        component: () => import(/* webpackChunkName: "room_index" */ '../views/land/LandIndex2')
    },
    {
        path: '/map/purchase',
        name: 'Map Purchase',
        component: () => import(/* webpackChunkName: "room_index" */ '../views/land/LandIndex2')
    },
    {
        path: '/estate',
        name: 'Estate Manager',
        component: () => import(/* webpackChunkName: "room_index" */ '../views/estate/EstateContainer')
    },
    {
        path: '/land',
        name: 'Owned Land',
        component: () => import(/* webpackChunkName: "room_index" */ '../views/land/LandListIndex')
    },
    {
        path: '/land/:x/:y',
        name: 'Owned Land',
        component: () => import(/* webpackChunkName: "room_index" */ '../views/land/LandShow')
    },
    {
        path: '/buildings',
        name: 'Buildings',
        component: () => import(/* webpackChunkName: "room_index" */ '../views/buildings/BuildingIndex')
    },
    {
        path: '/buildings/create',
        name: 'New Building',
        component: () => import(/* webpackChunkName: "room_index" */ '../views/buildings/BuildingCreate')
    },
    {
        path: '/buildings/:id',
        name: 'Show Building',
        component: () => import(/* webpackChunkName: "room_index" */ '../views/buildings/BuildingShow')
    },
    {
        path: '/buildings/:id/edit',
        name: 'Update Building',
        component: () => import(/* webpackChunkName: "room_index" */ '../views/buildings/BuildingCreate')
    },
    {
        path: '/oasis/create',
        name: 'Oasis Create',
        component: () => import(/* webpackChunkName: "room_index" */ '../views/tools/CreateRoom')
    },
    {
        path: '/oasis',
        name: 'Oasis Index',
        component: () => import(/* webpackChunkName: "room_index" */ '../views/rooms/RoomIndex')
    },
    {
        path: '/oasis/:id',
        name: 'Oasis Show',
        component: () => import(/* webpackChunkName: "room_show" */ '../views/rooms/RoomShow.vue')
    },
    {
        path: '/nft-import',
        name: 'NFT Import',
        component: () => import(/* webpackChunkName: "nft_import" */ '../views/NFTImport.vue')
    },
    {
        path: '/contracts/harmony/dice_one',
        name: 'Harmony Dice',
        component: () => import(/* webpackChunkName: "dice_one" */ '../views/contracts/DiceOne.vue')
    },
    {
        path: '/lounge/games',
        name: 'Games Lounge',
        component: () => import(/* webpackChunkName: "game_lounge" */ '../views/lounge/GameLoungeIndex.vue')
    },
    {
        path: '/lounge/games/classic_dice',
        name: 'Harmony Dice',
        component: () => import(/* webpackChunkName: "dice_one" */ '../views/contracts/DiceOne.vue')
    },
    {
        path: '/lounge/games/spin_to_win',
        name: 'Spin To Win',
        component: () => import(/* webpackChunkName: "spin_to_win" */ '../views/contracts/SpinToWin.vue')
    },
    {
        path: '/oasis/:id/visit',
        name: 'Rooms',
        component: () => import(/* webpackChunkName: "visit_room" */ '../views/rooms/VisitRoom.vue')
    },
    {
        path: '/admin/3c5bd2fb-4580-4f2d-8ee9-8cdb395c6217',
        name: 'Uploader',
        component: () => import(/* webpackChunkName: "visit_room" */ '../views/admin/TestItemUploader.vue')
    },
    {
        path: '/admin/remote_auth',
        name: 'Remote Auth',
        component: () => import(/* webpackChunkName: "visit_room" */ '../views/admin/RemoteGameAuth.vue')
    },
    {
        path: '/admin/items/upload',
        name: 'Uploader',
        component: () => import(/* webpackChunkName: "visit_room" */ '../views/admin/NewItemUploader')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
