import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag";
import 'animate.css';
import store from './store'
import WOW from "wow.js";

Vue.config.productionTip = false;


new WOW().init();


// GOOD
router.beforeEach((to, from, next) => {
  if (store.state.routerHistory.length > 5) {
    store.state.routerHistory.splice(-1, 1);
  }

  if (to.name !== 'Login' && store.state.wallet_addr === "") {
    store.state.routerHistory.push(to.fullPath);
    next({name: 'Login'})
    return
  }

  if (to.name !== 'Login') {
    store.state.routerHistory.push(from.fullPath);
  }

  next()
})

Vue.use(VueGtag, {
  config: {id: 'G-ZDGCCWZ2MM'},
  appName: 'AlphaBatem',
  pageTrackerScreenviewEnabled: true,
}, router)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')