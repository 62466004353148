import {api, apiHelper} from "./api";

export class User {
	prefix() {
		return "users"
	}

	index(cb, page = 0, limit = 50, sort = 'uid', sortOrder = 'desc') {
		return api.get(`${this.prefix()}?${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	indexItems(cb, page = 0, limit = 50, sort = 'uid', sortOrder = 'desc') {
		return api.get(`${this.prefix()}/items?${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	indexRooms(wallet_addr, page = 0, limit = 50, sort = 'uid', sortOrder = 'desc') {
		return api.get(`${this.prefix()}/${wallet_addr}/rooms?${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	indexLand(wallet_addr, page = 0, limit = 50, sort = 'uid', sortOrder = 'desc') {
		return api.get(`${this.prefix()}/${wallet_addr}/land?${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	indexLandUndeveloped(wallet_addr, page = 0, limit = 50, sort = 'uid', sortOrder = 'desc') {
		return api.get(`${this.prefix()}/${wallet_addr}/land/undeveloped?${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	indexBuildings(wallet_addr, page = 0, limit = 50, sort = 'uid', sortOrder = 'desc') {
		return api.get(`${this.prefix()}/${wallet_addr}/buildings?${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	show(uid) {
		return api.get(`${this.prefix()}/${uid}`)
	}

	create(obj) {
		return api.post(`${this.prefix()}`, obj)
	}

	delete(uid) {
		return api.delete(`${this.prefix()}/${uid}`)
	}
}

export default new User()