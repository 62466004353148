<template>
    <div id="app">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12 px-0 viewport">
					<router-view class="inner-view pl-0"></router-view>
				</div>
			</div>
		</div>
		<Sidenav></Sidenav>
	</div>
</template>

<script>
	import "@/assets/bootstrap/css/bootstrap.min.css";
	import "@/assets/css/ionicons.min.css";
	import 'owl.carousel/dist/assets/owl.carousel.css';
	import "@/assets/owlcarousel/css/owl.theme.css";
	import "@/assets/css/magnific-popup.css";
	import "@/assets/css/style.css";
	import "@/assets/css/responsive.css";
	import "@/assets/color/theme.css";
	import "@/assets/css/old/main.css";
	import "@/assets/css/old/additional.css";
	import "@/assets/css/theme_overrides.css";
	import "@/assets/css/animate.css";
	import Sidenav from "./components/Sidenav";
	
	export default {
		name: 'App',
		components: {
			Sidenav
		},
		mounted() {
		}
	}
</script>

<style>
    @import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap');


    div.show, .index {
        /*margin-bottom: 100px;*/
	}


	.viewport {
		height: 100vh;
		max-height: 100vh;
		overflow-y: scroll;
		overflow-x: hidden;
		background: #1e1e1f;
	}

	.inner-view {
		margin-left: 60px;
		width: 98%;
	}
</style>
