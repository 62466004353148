import axios from 'axios';

const BASE_ADDR = "https://app.alphabatem.com";
// const BASE_ADDR = "http://localhost:9093";

export const api = axios.create({
	baseURL: BASE_ADDR //TODO move to env
});

api.interceptors.response.use(response => {
	return response;
}, error => {
	if (error?.response?.status === 401) {
		window.location.href = `${BASE_ADDR}/auth/login`
	}
	return Promise.reject(error);
});


export const apiHelper = {
	buildPaginationQuery(page = 0, limit = 50, sort = '', sortOrder = 'desc') {
		let query = `page=${page}&limit=${limit}`;

		if (sort !== '')
			query += `&sort=${sort},${sortOrder}`;

		return query;
	}
};